@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  font-style: normal;
  src: url('../font//Poppins-300/Poppins-300.eot');
  src: url('../font//Poppins-300/Poppins-300.eot?#iefix') format('embedded-opentype'),
       local('Poppins Light'),
       local('Poppins-300'),
       url('../font//Poppins-300/Poppins-300.woff2') format('woff2'),
       url('../font//Poppins-300/Poppins-300.woff') format('woff'),
       url('../font//Poppins-300/Poppins-300.ttf') format('truetype'),
       url('../font//Poppins-300/Poppins-300.svg#Poppins') format('svg');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: normal;
  src: url('../font//Poppins-600/Poppins-600.eot');
  src: url('../font//Poppins-600/Poppins-600.eot?#iefix') format('embedded-opentype'),
       local('Poppins SemiBold'),
       local('Poppins-600'),
       url('../font//Poppins-600/Poppins-600.woff2') format('woff2'),
       url('../font//Poppins-600/Poppins-600.woff') format('woff'),
       url('../font//Poppins-600/Poppins-600.ttf') format('truetype'),
       url('../font//Poppins-600/Poppins-600.svg#Poppins') format('svg');
}
