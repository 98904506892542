@font-face {
  font-family: 'doka_Iconfont';
  src:  url('../font/doka_Iconfont/doka_Iconfont.eot?umgfcw');
  src:  url('../font/doka_Iconfont/doka_Iconfont.eot?umgfcw#iefix') format('embedded-opentype'),
    url('../font/doka_Iconfont/doka_Iconfont.ttf?umgfcw') format('truetype'),
    url('../font/doka_Iconfont/doka_Iconfont.woff?umgfcw') format('woff'),
    url('../font/doka_Iconfont/doka_Iconfont.svg?umgfcw#doka_Iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="doka-icon-"], [class*=" doka-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'doka_Iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.doka-icon-addperson:before {
  content: "\e900";
}
.doka-icon-admin:before {
  content: "\e901";
}
.doka-icon-alertbubble:before {
  content: "\e902";
}
.doka-icon-allread:before {
  content: "\e903";
}
.doka-icon-article:before {
  content: "\e904";
}
.doka-icon-automaticdoor:before {
  content: "\e905";
}
.doka-icon-back:before {
  content: "\e906";
}
.doka-icon-badge:before {
  content: "\e907";
}
.doka-icon-battery-charging:before {
  content: "\e908";
}
.doka-icon-battery-discharging:before {
  content: "\e909";
}
.doka-icon-battery-empty:before {
  content: "\e90a";
}
.doka-icon-battery-full:before {
  content: "\e90b";
}
.doka-icon-battery-half:before {
  content: "\e90c";
}
.doka-icon-battery-low:before {
  content: "\e90d";
}
.doka-icon-bell:before {
  content: "\e90e";
}
.doka-icon-bellalternative:before {
  content: "\e90f";
}
.doka-icon-bellinverted:before {
  content: "\e910";
}
.doka-icon-calculator:before {
  content: "\e911";
}
.doka-icon-certified:before {
  content: "\e912";
}
.doka-icon-checkboxPartialSelect:before {
  content: "\e913";
}
.doka-icon-checked:before {
  content: "\e914";
}
.doka-icon-clock:before {
  content: "\e915";
}
.doka-icon-close:before {
  content: "\e916";
}
.doka-icon-closeinline:before {
  content: "\e917";
}
.doka-icon-confused:before {
  content: "\e918";
}
.doka-icon-connected:before {
  content: "\e919";
}
.doka-icon-copy:before {
  content: "\e91a";
}
.doka-icon-customer:before {
  content: "\e91b";
}
.doka-icon-default:before {
  content: "\e91c";
}
.doka-icon-denied:before {
  content: "\e91d";
}
.doka-icon-detailspecificaton:before {
  content: "\e91e";
}
.doka-icon-disconnected_var_2:before {
  content: "\e91f";
}
.doka-icon-disconnected:before {
  content: "\e920";
}
.doka-icon-discussion:before {
  content: "\e921";
}
.doka-icon-document:before {
  content: "\e922";
}
.doka-icon-door:before {
  content: "\e923";
}
.doka-icon-doorsmall:before {
  content: "\e924";
}
.doka-icon-drag:before {
  content: "\e925";
}
.doka-icon-draggable:before {
  content: "\e926";
}
.doka-icon-dropdown:before {
  content: "\e927";
}
.doka-icon-edit:before {
  content: "\e928";
}
.doka-icon-externallink:before {
  content: "\e929";
}
.doka-icon-filter:before {
  content: "\e92a";
}
.doka-icon-floorplan:before {
  content: "\e92b";
}
.doka-icon-fullscreen:before {
  content: "\e92c";
}
.doka-icon-functionshidden:before {
  content: "\e92d";
}
.doka-icon-gear:before {
  content: "\e92e";
}
.doka-icon-gearalternative:before {
  content: "\e92f";
}
.doka-icon-hardwaremapping:before {
  content: "\e930";
}
.doka-icon-home:before {
  content: "\e931";
}
.doka-icon-image:before {
  content: "\e932";
}
.doka-icon-info:before {
  content: "\e933";
}
.doka-icon-info2:before {
  content: "\e934";
}
.doka-icon-key:before {
  content: "\e935";
}
.doka-icon-keyfob:before {
  content: "\e936";
}
.doka-icon-leavefs:before {
  content: "\e937";
}
.doka-icon-lightsiren:before {
  content: "\e938";
}
.doka-icon-list:before {
  content: "\e939";
}
.doka-icon-logout:before {
  content: "\e93a";
}
.doka-icon-maximizepanel:before {
  content: "\e93b";
}
.doka-icon-mcheckactive:before {
  content: "\e93c";
}
.doka-icon-mcheckinactive:before {
  content: "\e93d";
}
.doka-icon-medias:before {
  content: "\e93e";
}
.doka-icon-megaphone:before {
  content: "\e93f";
}
.doka-icon-menu:before {
  content: "\e940";
}
.doka-icon-minimizepanel:before {
  content: "\e941";
}
.doka-icon-more:before {
  content: "\e942";
}
.doka-icon-mradioactive:before {
  content: "\e943";
}
.doka-icon-mradioinactive:before {
  content: "\e944";
}
.doka-icon-networkerror:before {
  content: "\e945";
}
.doka-icon-news:before {
  content: "\e946";
}
.doka-icon-noaccess:before {
  content: "\e947";
}
.doka-icon-openinline:before {
  content: "\e948";
}
.doka-icon-order:before {
  content: "\e949";
}
.doka-icon-orders:before {
  content: "\e94a";
}
.doka-icon-partner:before {
  content: "\e94b";
}
.doka-icon-partnersupport:before {
  content: "\e94c";
}
.doka-icon-partspec:before {
  content: "\e94d";
}
.doka-icon-paste:before {
  content: "\e94e";
}
.doka-icon-personalizedbadge:before {
  content: "\e94f";
}
.doka-icon-persons:before {
  content: "\e950";
}
.doka-icon-plus:before {
  content: "\e951";
}
.doka-icon-plus2:before {
  content: "\e952";
}
.doka-icon-polygonalzone:before {
  content: "\e953";
}
.doka-icon-profile:before {
  content: "\e954";
}
.doka-icon-qr:before {
  content: "\e955";
}
.doka-icon-question:before {
  content: "\e956";
}
.doka-icon-radiochecked:before {
  content: "\e957";
}
.doka-icon-radiounchecked:before {
  content: "\e958";
}
.doka-icon-rdparty:before {
  content: "\e959";
}
.doka-icon-reader:before {
  content: "\e95a";
}
.doka-icon-rectangularzone:before {
  content: "\e95b";
}
.doka-icon-recyclebin:before {
  content: "\e95c";
}
.doka-icon-reload2:before {
  content: "\e95d";
}
.doka-icon-reloadalternative:before {
  content: "\e95e";
}
.doka-icon-rmo:before {
  content: "\e95f";
}
.doka-icon-schoolbell:before {
  content: "\e960";
}
.doka-icon-search:before {
  content: "\e961";
}
.doka-icon-security:before {
  content: "\e962";
}
.doka-icon-services:before {
  content: "\e963";
}
.doka-icon-setupmedia:before {
  content: "\e964";
}
.doka-icon-site:before {
  content: "\e965";
}
.doka-icon-smartkey:before {
  content: "\e966";
}
.doka-icon-smiley:before {
  content: "\e967";
}
.doka-icon-sortascending:before {
  content: "\e968";
}
.doka-icon-sortdescending:before {
  content: "\e969";
}
.doka-icon-sound:before {
  content: "\e96a";
}
.doka-icon-spinner:before {
  content: "\e96b";
}
.doka-icon-success:before {
  content: "\e96c";
}
.doka-icon-thumbdown:before {
  content: "\e96d";
}
.doka-icon-thumbundecided:before {
  content: "\e96e";
}
.doka-icon-thumbup:before {
  content: "\e96f";
}
.doka-icon-unchecked:before {
  content: "\e970";
}
.doka-icon-undo:before {
  content: "\e971";
}
.doka-icon-warningbubble:before {
  content: "\e972";
}
.doka-icon-warningdiamond:before {
  content: "\e973";
}
.doka-icon-warningtriangle:before {
  content: "\e974";
}
.doka-icon-wireless_01:before {
  content: "\e975";
}
.doka-icon-wireless_02:before {
  content: "\e976";
}
.doka-icon-wireless_03:before {
  content: "\e977";
}
.doka-icon-wireless_04:before {
  content: "\e978";
}
.doka-icon-wireless:before {
  content: "\e979";
}
.doka-icon-zones:before {
  content: "\e97a";
}
.doka-icon-zonesmall:before {
  content: "\e97b";
}
.doka-icon-zoom:before {
  content: "\e97c";
}
.doka-icon-zoomout:before {
  content: "\e97d";
}
.doka-icon-positon:before {
  content: "\e981";
}
